import React from "react";
import { useTranslation } from "react-i18next";

function Footer() {
  const { t } = useTranslation();
  return (
    <div className="footer">
      <div className="footer-inner">
        <h1>{t(`footer.a`)}</h1>

        <div className="footer-section-box">
          <span>
            Address : 6131 Orangethorpe Ave., Suite #260-A, Buena Park, CA
            90620, USA{" "}
          </span>
          <span>
            Tel : +1 (714) 709 0542 | E-mail : shlee@bncamericainc.com
          </span>
        </div>
        <div className="footer-section-box">
          <span className="copy">
            BNC America Inc. 2024 &copy; All rights reserved.
          </span>
        </div>
      </div>
    </div>
  );
}

export default Footer;
